import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Container, Row, Col, Visible } from 'react-grid-system'
import Button from '../../elements/button'
import Particles from 'react-particles-js'

export default function Outro(props) {
    return (
        <div className="onecol-container py-40 md:py-96 particle-parent">
            <Container>
                <Row>
                    <Col md={8} sm={12} className="mx-auto text-center z-50">
                        <h5 className="text-orange-300">{props.subtitle}</h5>
                        <h2 className="text-green-300">{props.title}</h2>
                        <ReactMarkdown children={props.description} className="mb-20" />
                        {!props.home ?
                            <Button ghost link={props.link} href={props.href} />
                        : 
                            <>
                                <span className="inline-flex mr-5"><Button orange borderHover link='Learn More' href="/business-case" /></span>
                                <Button ghost link='Contact Us' href="/contact" /> 
                            </>
                        }
                    </Col>
                </Row>
            </Container>
            <Visible lg xl xxl >
                <Particles
                    params={{
                        "particles": {
                            "line_linked": {
                                "shadow": {
                                    "enable": true,
                                    "color": "#000",
                                    "blur": 2
                                }
                            },
                            "number": {
                                "value": 50
                            },
                            "size": {
                                "value": 0.5,
                            },
                            "move": {
                                "random": true,
                                "speed": 3,
                                "direction": "top",
                                "out_mode": "out"
                            },                            
                            "color": "red"
                        },
                        "interactivity": {
                            "events": {
                            "onclick": {
                                "enable": true,
                                "mode": "repulse"
                            }
                            }
                        }
                    }} 
                />
            </Visible>
        </div>
    )
}